import React from "react";
import { MdOutlineLocationOn, MdOutlineEmail } from "react-icons/md";
import { BsTelephoneInbound } from "react-icons/bs";
import { FaFacebookSquare } from "react-icons/fa";
import { IoTimeOutline } from "react-icons/io5";

const HomeFooter = () => {
  return (
    <div
      className="xs:h-[100vh] bg-cover py-8 bg-white bg-center text-gray-800"
      // style={{
      //   backgroundImage: "url(footer_cover.jpg)",
      // }}
    >
      <div className="w-full md:w-[700px] mx-auto">
        <div className="grid xs:grid-cols-1 md:grid-cols-2 gap-4">
          <div className="mx-10 md:mx-0">
            <div className="flex items-center gap-2 mt-4">
              <MdOutlineLocationOn className="w-10 sm:w-10 h-10" />
              <p>Эрдэнэт Уурхайчин-1 хотхон 4 байр 1 давхарт 1105 тоот</p>
            </div>
            <div className="flex gap-2 mt-4">
              <MdOutlineEmail className=" w-6 h-6" />
              <p className="pl-1">
                <a href="mailto:erdenetcargo.mn@gmail.com">
                  erdenetcargo.mn@gmail.com
                </a>
              </p>
            </div>
            <div className="flex gap-2 mt-4">
              <BsTelephoneInbound className=" w-[20px] h-6" />
              <p className="pl-1">
                <a href="tel:+97696060645">96060645</a>,{" "}
                <a href="tel:+97696468182">96468182</a>
              </p>
            </div>
            <div
              className="flex gap-2 mt-4 items-center cursor-pointer"
              onClick={() =>
                window.open("https://www.facebook.com/erdenetcargo.mn/")
              }
            >
              <FaFacebookSquare className=" w-[20px] h-6" />
              <p className="pl-1">Facebook page</p>
            </div>
            <div className="flex gap-2 mt-4">
              <IoTimeOutline className=" w-[20px] h-6" />
              <p className="pl-1">Ажлын цаг:</p>
            </div>
            <div className="pl-8">
              <div>Даваа-Бямба 10:00-19:00</div>
              <div>Ням Амарна</div>
            </div>
          </div>
          <div className="mx-10 md:mx-0">
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2615.3933755133903!2d104.04614357746627!3d49.041143587826824!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x5d9f51007f6ac057%3A0x3c5eb18d85b5197f!2zRXJkZW5ldCBDYXJnbyDQrdGA0LTRjdC90Y3RgiDQutCw0YDQs9C-!5e0!3m2!1sen!2smn!4v1723446831431!5m2!1sen!2smn"
              width={300}
              height={300}
              style={{ border: 0 }}
              allowFullScreen
              loading="lazy"
              referrerPolicy="no-referrer-when-downgrade"
              title="map"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default React.memo(HomeFooter);
