import React, { useState } from "react";
import { toast } from "react-toastify";
import { post, put } from "./axios";
import moment from "moment";
import { ClearIcon } from "./components/Icons";

const CheckModal = ({
  date,
  setDate,
  modal,
  setModal,
  setrefresh,
  refresh,
  name,
  setName,
  price,
  setPrice,
  metrkub,
  setMetrkub,
  setPid,
  pid,
}) => {
  const [isopen, setIsopen] = useState(false);

  const dropdownItems = [
    { id: 1, text: "Эрээн - УБ" },
    { id: 2, text: "УБ дотор хүргэлт" },
    { id: 3, text: "УБ - ЭТ" },
    { id: 4, text: "ЭТ дотор хүргэлт" },
    { id: 5, text: "Time Worker" },
  ];

  const save = () => {
    if (name && price) {
      const params = {
        name,
        price,
        metrkub,
        create_date: moment(date).format("YYYY-MM-DD"),
      };
      if (pid) {
        put(`/cargo/cost/${pid}`, params)
          .then((res) => {
            if (res.success) {
              setModal(false);
              toast.success(res?.data);
              setPrice("");
              setMetrkub("");
              setrefresh(refresh + 1);
              setPid(null);
              setDate("");
            } else {
              toast.warn(res?.error);
            }
          })
          .catch((err) => {
            toast.warn(err?.message);
          });
      } else {
        post("/cargo/cost", params)
          .then((res) => {
            if (res.success) {
              setModal(false);
              toast.success(res?.data);
              setPrice("");
              setMetrkub("");
              setDate("");
              setrefresh(refresh + 1);
            } else {
              toast.warn(res?.error);
            }
          })
          .catch((err) => {
            toast.warn(err?.message);
          });
      }
    }
  };

  return (
    <React.Fragment>
      <div
        className={`${
          modal ? "fixed" : "hidden"
        } w-full h-full left-0 top-0 z-10 bg-black/50`}
        onClick={() => {
          setModal(false);
        }}
      />
      <div
        className={`${
          modal ? "fixed" : "hidden"
        } z-20 top-1/2 -translate-y-1/2 -translate-x-1/2 left-1/2 w-[95%] md:w-[700px] max-h-[95%] h-auto bg-white rounded animate-fade`}
      >
        <div className="flex items-center justify-between p-2 border-b">
          <div className="text-center text-sm">Зардал бүртгэх</div>
          <div
            onClick={() => {
              setModal(false);
            }}
            className="cursor-pointer"
          >
            <ClearIcon />
          </div>
        </div>
        <div className="w-full text-xs md:text-base">
          <input
            type="date"
            className="bg-white outline-none text-gray-900 text-sm block w-full p-2 border-r"
            placeholder="Эхлэх огноо"
            value={date}
            onChange={(e) => {
              setDate(e.target.value);
            }}
          />
          <div className="relative">
            <input
              className="bg-white border text-xs text-gray-900 block p-2.5 w-full outline-none h-[38px]"
              placeholder="Зардал нэр"
              type="text"
              value={name || ""}
              onChange={(e) => {
                setName(e.target.value);
              }}
              onFocus={() => {
                setIsopen(true);
              }}
              onBlur={() => {
                setTimeout(() => {
                  setIsopen(false);
                }, 200);
              }}
            />
            <div
              className={`${
                isopen ? "absolute" : "hidden"
              } top-[38px] left-2.5 bg-white`}
            >
              {dropdownItems.map((item) => (
                <div
                  key={item.id}
                  className="p-2 border-b last:border-b-0 select-none cursor-pointer"
                  onClick={() => {
                    setName(item.text);
                  }}
                >
                  {item.text}
                </div>
              ))}
            </div>
          </div>
          <input
            className="bg-white border text-xs text-gray-900 block p-2.5 w-full outline-none h-[38px]"
            placeholder="Метр куб (заавал биш)"
            type="number"
            inputMode="decimal"
            pattern="[0-9]"
            value={metrkub || ""}
            onChange={(e) => {
              setMetrkub(e.target.value);
            }}
          />
          <input
            className="bg-white border text-xs text-gray-900 block p-2.5 w-full outline-none h-[38px]"
            placeholder="Үнэ"
            type="number"
            inputMode="decimal"
            pattern="[0-9]"
            value={price || ""}
            onChange={(e) => {
              setPrice(e.target.value);
            }}
          />
          <div
            className={`px-4 py-2 bg-green-600 rounded text-white mt-2 text-center cursor-pointer`}
            onClick={() => {
              save();
            }}
          >
            Хадгалах
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default React.memo(CheckModal);
