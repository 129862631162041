import React, { useContext, useEffect, useState } from "react";
import { Context } from "./context";
import { toast } from "react-toastify";
import { get, post, trash } from "./axios";
import Loader from "./components/loader";
import AdminHeader from "./components/adminHeader";
import { Link } from "react-router-dom";
import { FaRegTrashAlt } from "react-icons/fa";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import LazyImg from "./components/lazy_img";
import { ConfirmDialog, confirmDialog } from "primereact/confirmdialog";
import { FaCheck } from "react-icons/fa";
import { FaCheckDouble } from "react-icons/fa";
import _ from "lodash";
import copy from "copy-to-clipboard";
import { Dropdown } from "primereact/dropdown";

const combine_url = (salescount, real_id) => {
  // var url = `http://a.m.taobao.com/i${product_id}.htm?skuId=${real_id}`;
  var url = `https://item.taobao.com/item.htm?id=${salescount}&skuId=${real_id}`;
  return url;
};

const Orders = () => {
  const { state } = useContext(Context);
  const [loading, setLoading] = useState(false);
  const [list, setList] = useState([]);
  const [expandedRows, setExpandedRows] = useState(null);
  const [filter, setFilter] = useState("Захиалга хийгээгүй");
  const [selected, setSelected] = useState(0);
  const selection = [
    { name: "Устгаагүй", value: 0 },
    { name: "Устгасан", value: 1 },
  ];
  const [rowClick, setRowClick] = useState(null);

  const fetchdata = async () => {
    setLoading(true);
    try {
      const res = await get("/cargo/invoice");
      if (res.success) {
        let filtered = res.data;
        if (state.userId !== 1) {
          filtered = _.filter(res.data, (item) => {
            return item.location === "UB";
          });
        }
        filtered = _.filter(filtered, (item) => {
          if (filter === "Захиалга хийсэн") {
            return item.comment === "Захиалга хийсэн";
          } else {
            return item.comment !== "Захиалга хийсэн";
          }
        });
        setList(
          _.orderBy(
            _.filter(filtered, (item) => {
              if (selected === 0) {
                return !item.delete_date;
              } else {
                return item.delete_date;
              }
            }),
            ["id"],
            ["desc"]
          )
        );
      }
    } catch (error) {
      toast.error(
        error?.response?.data?.message
          ? error?.response?.data?.message
          : error?.message
      );
    }
    setLoading(false);
  };

  useEffect(() => {
    if (state.userId) {
      fetchdata();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.userId, selected, filter]);

  const allowExpansion = (rowData) => {
    return rowData.cargo_orders.length > 0;
  };

  const rowExpansionTemplate = (data) => {
    return (
      <DataTable value={data.cargo_orders} className="mb-10">
        <Column
          field="image"
          header="Зураг"
          className="w-24 text-center"
          alignHeader={"center"}
          body={(data) => {
            return (
              <LazyImg
                alt={`item_image_${data.name}`}
                src={data?.image}
                css={`object-cover w-20 h-20 rounded-md`}
                minsize={220}
                maxsize={220}
              />
            );
          }}
        />
        <Column field="count" header="ш" />
        <Column
          field="price"
          header="Үнэ ₮"
          body={(data) => {
            return parseFloat(data.price)?.toLocaleString();
          }}
        />
        <Column
          field="delivery_price"
          header="Хүргэлт үнэ ₮"
          body={(data) => {
            return parseFloat(data.delivery_price)?.toLocaleString();
          }}
        />
        <Column
          field="price_cn"
          header="Үнэ Y"
          body={(data) => {
            return parseFloat(data.price_cn)?.toLocaleString();
          }}
        />
        <Column
          field="delivery_price_cn"
          header="Хүргэлт үнэ Y"
          body={(data) => {
            return parseFloat(data.delivery_price_cn)?.toLocaleString();
          }}
        />
        <Column
          field="id"
          header="Линк"
          body={(data) => {
            return (
              <div className="flex items-center justify-center">
                <div
                  className={`p-2 rounded-md ${
                    rowClick === data?.id ? "bg-green-700" : "bg-green-500"
                  } text-white cursor-pointer`}
                  onClick={() => {
                    var aa = combine_url(data?.salescount, data?.real_id);
                    copy(aa);
                    setRowClick(data?.id);
                    toast.success("Хуулагдлаа");
                  }}
                >
                  Хуулах
                </div>
              </div>
            );
          }}
        />
        <Column
          field="id"
          header="Нээх"
          align={"center"}
          className="text-center"
          body={(data) => {
            return (
              <Link
                to={combine_url(data?.salescount, data?.real_id)}
                target="_blank"
                className="p-2 rounded-md bg-blue-500 text-white"
              >
                Нээх
              </Link>
            );
          }}
        />
      </DataTable>
    );
  };

  const InvoiceDelete = async (id) => {
    confirmDialog({
      headerClassName: "p-2 text-center",
      contentClassName: "p-0",
      footerClassName: "p-2",
      header: "Захиалга устгах гэж байна. Та итгэлтэй байна уу?",
      icon: "pi pi-exclamation-triangle",
      acceptLabel: "Тийм",
      rejectLabel: "Үгүй",
      accept: async () => {
        setLoading(true);
        try {
          const res = await trash(`/cargo/invoice/${id}`);
          if (res?.success) {
            toast.success("Амжилттай устгагдлаа.");
            await fetchdata();
          }
        } catch (error) {
          toast.error(
            error.response.data.message
              ? error.response.data.message
              : error.message
          );
        } finally {
          setLoading(false);
        }
      },
      reject: () => {},
    });
  };

  const InvoicePaid = async (data) => {
    confirmDialog({
      headerClassName: "p-2 text-center",
      contentClassName: "p-0",
      footerClassName: "p-2",
      header:
        "Захиалгын төлбөр төлөгдсөн болгох гэж байна. Та итгэлтэй байна уу?",
      icon: "pi pi-exclamation-triangle",
      acceptLabel: "Тийм",
      rejectLabel: "Үгүй",
      accept: async () => {
        setLoading(true);
        try {
          const res = await post(`/cargo/invoice/paid`, {
            id: data?.id,
            paid: data?.paid ? "0" : "1",
          });
          if (res?.success) {
            toast.success(res?.data);
            await fetchdata();
          }
        } catch (error) {
          toast.error(
            error.response.data.message
              ? error.response.data.message
              : error.message
          );
        } finally {
          setLoading(false);
        }
      },
      reject: () => {},
    });
  };

  const InvoiceOrdered = async (data) => {
    confirmDialog({
      headerClassName: "p-2 text-center",
      contentClassName: "p-0",
      footerClassName: "p-2",
      header: "Захиалга хийсэн төлөвт оруулах гэж байна. Та итгэлтэй байна уу?",
      icon: "pi pi-exclamation-triangle",
      acceptLabel: "Тийм",
      rejectLabel: "Үгүй",
      accept: async () => {
        setLoading(true);
        try {
          const res = await post(`/cargo/invoice/comment/${data?.id}`, {
            comment: "Захиалга хийсэн",
          });
          if (res?.success) {
            toast.success(res?.data);
            await fetchdata();
          }
        } catch (error) {
          toast.error(
            error.response.data.message
              ? error.response.data.message
              : error.message
          );
        } finally {
          setLoading(false);
        }
      },
      reject: () => {},
    });
  };

  if (!state?.loggedIn)
    return (
      <div className="w-screen h-screen flex items-center justify-center flex-col">
        <div className="text-white text-xl">Нэвтрэх шаардлагатай.</div>
        <Link
          to={"/login"}
          className="px-6 py-2 rounded-md bg-green-500 text-white mt-2"
        >
          Нэвтрэх
        </Link>
      </div>
    );

  if (!state?.is_sa)
    return (
      <div className="w-screen h-screen flex items-center justify-center flex-col">
        <div className="text-white text-xl">Хандах эрхгүй.</div>
        <Link
          to={"/"}
          className="px-6 py-2 rounded-md bg-green-500 text-white mt-2"
        >
          Нүүр хуудасруу буцах
        </Link>
      </div>
    );

  return (
    <div>
      {loading && <Loader />}
      <ConfirmDialog />
      <AdminHeader />

      <div className="flex flex-col gap-2 mt-2 px-2 container mx-auto">
        <Dropdown
          value={filter}
          onChange={(e) => {
            setFilter(e.value);
          }}
          options={[
            { name: "Захиалга хийгээгүй", value: "Захиалга хийгээгүй" },
            { name: "Захиалга хийсэн", value: "Захиалга хийсэн" },
          ]}
          optionLabel="name"
        />
        <Dropdown
          value={selected}
          onChange={(e) => {
            setSelected(e.value);
          }}
          options={selection}
          optionLabel="name"
        />
        <DataTable
          value={list}
          size="small"
          className="text-sm sm:text-base"
          emptyMessage="Хоосон байна."
          // sortField="paid"
          // sortOrder={-1}
          expandedRows={expandedRows}
          onRowToggle={(e) => setExpandedRows(e.data)}
          rowExpansionTemplate={rowExpansionTemplate}
          header={
            <div className="flex items-center justify-between">
              <div>Захиалгууд</div>
            </div>
          }
        >
          <Column expander={allowExpansion} style={{ width: "5rem" }} />
          <Column
            field="id"
            header="№"
            body={(data) => {
              return (
                <Link
                  to={`https://shop.erdenetcargo.mn/invoice/${data.id}`}
                  target="_blank"
                  className="text-blue-500 underline"
                >
                  {data?.id}
                </Link>
              );
            }}
          />
          <Column
            field="invoice_number"
            header="Дугаар"
            sortable
            bodyClassName={"text-nowrap"}
          />
          <Column
            field="create_date"
            header="Огноо"
            sortable
            bodyClassName={"text-nowrap"}
          />
          <Column
            field="phone"
            header="Утас"
            sortable
            body={(data) => {
              return (
                <div
                  onClick={() => {
                    copy(`${data.phone}etet`);
                  }}
                >
                  {data.phone}
                </div>
              );
            }}
          />
          {state?.is_sa && (
            <Column field="location" header="Байршил" sortable />
          )}
          <Column
            field="payment"
            header="Үнэ"
            sortable
            body={(data) => {
              return parseFloat(data.payment)?.toLocaleString();
            }}
          />
          <Column
            field="paid"
            header="Төлбөр"
            sortable
            body={(data) => {
              return data.paid ? (
                <span className="font-semibold text-green-700">Төлөгдсөн</span>
              ) : (
                <span className="font-semibold text-red-700">Төлөөгүй</span>
              );
            }}
          />
          <Column
            field="comment"
            header="Коммэнт"
            body={(data) => {
              return (
                <div
                  className={`${
                    data.comment === "Захиалга хийсэн"
                      ? "font-semibold text-green-700"
                      : ""
                  }`}
                >
                  {data.comment}
                </div>
              );
            }}
          />
          <Column
            field="id"
            header="#"
            alignHeader={"center"}
            body={(data) => {
              return (
                <div className="flex gap-3 items-center justify-center">
                  <FaRegTrashAlt
                    className="cursor-pointer text-2xl text-red-600"
                    onClick={() => {
                      InvoiceDelete(data.id);
                    }}
                  />
                  <FaCheck
                    className="cursor-pointer text-2xl text-green-600"
                    onClick={() => {
                      InvoicePaid(data);
                    }}
                  />
                  <FaCheckDouble
                    className="cursor-pointer text-2xl text-green-600"
                    onClick={() => {
                      InvoiceOrdered(data);
                    }}
                  />
                </div>
              );
            }}
          />
        </DataTable>
      </div>
    </div>
  );
};

export default React.memo(Orders);
