import React, { useReducer, useEffect } from "react";
import { AppReducer } from "./reducer";
import { jwtDecode } from "jwt-decode";

export const Context = React.createContext();

const initialState = {
  token: undefined,
  role: null,
  userId: null,
  loggedIn: false,
  sidebar: false,
  is_sa: false,
};

const AppContext = ({ children }) => {
  const [state, dispatch] = useReducer(AppReducer, initialState);

  const token = localStorage.getItem("token");

  useEffect(() => {
    if (token) {
      const decoded = jwtDecode(token);
      dispatch({
        type: "CHANGE",
        data: {
          token: token,
          role: decoded?.role,
          userId: decoded?.id,
          loggedIn: true,
          is_sa: decoded?.id === 1,
        },
      });
    }
  }, [token]);

  return (
    <Context.Provider value={{ state, dispatch }}>{children}</Context.Provider>
  );
};

export default React.memo(AppContext);
