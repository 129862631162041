import React, { useContext, useEffect, useState } from "react";
import { Context } from "./context";
import { get, post, put } from "./axios";
import { FaRegArrowAltCircleDown } from "react-icons/fa";
import { toast } from "react-toastify";
import moment from "moment";
import _ from "lodash";
import Loader from "./components/loader";
import AdminHeader from "./components/adminHeader";
import { Link } from "react-router-dom";
import { InputSwitch } from "primereact/inputswitch";

const File = () => {
  const { state } = useContext(Context);
  const [file, setFile] = useState(null);
  const [loading, setLoading] = useState(false);
  const [startdate, setStartdate] = useState("");
  const [enddate, setEnddate] = useState("");
  const [data, setData] = useState({});
  const [statement, setStatement] = useState([]);
  const [refresh, setRefresh] = useState(0);
  const [list, setList] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const [resp, setResp] = useState({
    inserted: [],
    newrecord: [],
    duplicated: [],
  });
  const [config_refresh, setConfig_refresh] = useState(0);
  const config_statement = _.find(data, { name: "statement" });
  const config_cargo = _.find(data, { name: "cargo" });

  useEffect(() => {
    get("/cargo/statement")
      .then((res) => {
        if (res.success) {
          setList(res?.data);
        }
      })
      .catch((err) => {
        toast.warn(err.message);
      });
  }, [refresh]);

  useEffect(() => {
    get("/cargo/config")
      .then((res) => {
        if (res.success) {
          setData(res?.data);
        }
      })
      .catch((err) => {
        toast.warn(err.message);
      });
  }, [config_refresh]);

  useEffect(() => {
    const filteredList = searchValue
      ? _.filter(list, (item) =>
          _.some(
            ["amount", "transactionDate", "transactionRemarks", "txnTime"],
            (key) => _.includes(item[key], searchValue)
          )
        )
      : list;

    setStatement(Object.entries(_.groupBy(filteredList, "transactionDate")));
  }, [searchValue, list]);

  const UploadFile = async () => {
    if (file) {
      try {
        setResp({
          inserted: [],
          newrecord: [],
          duplicated: [],
        });
        setLoading(true);
        const content = await new Promise((resolve, reject) => {
          const reader = new FileReader();
          reader.onload = (event) => resolve(event.target.result);
          reader.onerror = (error) => reject(error);
          reader.readAsText(file);
        });
        var file_name = file?.name?.split(".");
        const list = content?.split("\r\n")?.map((line) => line?.split(","));
        if (list?.length > 0) {
          const pplist = [];
          for (const el of list) {
            var track = el[0];
            if (/^[A-Za-z0-9-]+$/.test(track)) {
              var date = file_name[0]
                ? file_name[0]
                : moment().format("YYYY-MM-DD");

              if (date && track) {
                pplist.push({
                  track: track,
                  registered: date,
                  status: "Эрээнд ирсэн",
                });
              }
            }
          }
          if (pplist.length > 0) {
            const res = await post("/cargo/file_create", { data: pplist });
            if (res.success) {
              setResp({
                inserted: res.insertedRecords,
                newrecord: res.newRecords,
                duplicated: res.duplicateRecords,
              });
              toast.success(res.message);
            } else {
              toast.warn(res.error);
            }
          }
        }
      } catch (error) {
        toast.warn(error.message);
      } finally {
        setLoading(false);
      }
    }
  };

  const ClearDB = async () => {
    if (startdate && enddate) {
      try {
        setLoading(true);
        const res = await post(
          `/cargo/cleardb?startdate=${startdate}&enddate=${enddate} 24:00:00`
        );
        if (res.success) {
          toast.success(res.data);
        } else {
          toast.warn(res.error);
        }
      } catch (error) {
        toast.warn(error.message);
      } finally {
        setLoading(false);
      }
    } else {
      toast.warn("Эхлэх дуусах огноо сонгоно уу.");
    }
  };

  const workStatement = (value) => {
    setLoading(true);
    put("/cargo/config/1", {
      data: value ? "work" : "notwork",
    })
      .then((res) => {
        if (res?.success) {
          setConfig_refresh((old) => old + 1);
          toast.success(res?.data);
        } else {
          toast.warn(res.error);
        }
      })
      .catch((err) => {
        toast.warn(err?.message);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const configCargo = (value) => {
    setLoading(true);
    put("/cargo/config/2", {
      data: value ? "work" : "notwork",
    })
      .then((res) => {
        if (res?.success) {
          setConfig_refresh((old) => old + 1);
          toast.success(res?.data);
        } else {
          toast.warn(res.error);
        }
      })
      .catch((err) => {
        toast.warn(err?.message);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const PaymentCheck = async () => {
    setLoading(true);
    await get("/cargo/payment_check");
    setRefresh((old) => old + 1);
    setLoading(false);
  };

  useEffect(() => {
    PaymentCheck();
  }, []);

  if (!state?.loggedIn)
    return (
      <div className="w-screen h-screen flex items-center justify-center flex-col">
        <div className="text-white text-xl">Нэвтрэх шаардлагатай.</div>
        <Link
          to={"/login"}
          className="px-6 py-2 rounded-md bg-green-500 text-white mt-2"
        >
          Нэвтрэх
        </Link>
      </div>
    );

  return (
    <React.Fragment>
      <AdminHeader />
      <div className="pt-0 pb-16 w-full md:w-[700px] mx-auto px-4">
        {loading && <Loader />}
        {state?.is_sa && (
          <>
            <div className="flex items-center pt-4">
              <div className="w-[200px] h-[50px] bg-white flex items-center justify-center rounded-l overflow-hidden relative">
                <label
                  htmlFor="dropzone-file"
                  className="cursor-pointer flex items-center justify-center gap-2 w-full h-full p-2"
                >
                  <svg
                    className="w-8 h-8 text-gray-500"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 20 16"
                  >
                    <path
                      stroke="currentColor"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M13 13h3a3 3 0 0 0 0-6h-.025A5.56 5.56 0 0 0 16 6.5 5.5 5.5 0 0 0 5.207 5.021C5.137 5.017 5.071 5 5 5a4 4 0 0 0 0 8h2.167M10 15V6m0 0L8 8m2-2 2 2"
                    />
                  </svg>
                  <div className="text-xs">
                    {file?.name ? file?.name : "Файл сонгох"}
                  </div>
                  <input
                    id="dropzone-file"
                    type="file"
                    className="hidden"
                    onChange={(e) => setFile(e.target.files[0])}
                  />
                </label>
              </div>
              <div
                className="px-4 h-[50px] flex items-center justify-center bg-green-600 text-white rounded-r cursor-pointer"
                onClick={() => {
                  if (!loading) {
                    UploadFile();
                  }
                }}
              >
                {loading ? "Уншиж байна" : "Хуулах"}
              </div>
            </div>
            <div className="flex items-center gap-2">
              <div>Давхардаагүй:</div>
              <div>{resp?.newrecord?.length}</div>
            </div>
            <div className="flex items-center gap-2">
              <div>Бүртгэгдсэн:</div>
              <div>{resp?.inserted?.length}</div>
            </div>
            <div>
              {_.map(resp?.duplicated, (el) => (
                <div className="flex items-center gap-2">
                  <div>{el?.registered}</div>
                  <div>{el?.track}</div>
                  <div>{el?.registered}</div>
                </div>
              ))}
            </div>
            <div className="mt-4">
              <div className="text-white">Бааз цэвэрлэх</div>
              <div className="flex">
                <input
                  type="date"
                  className="bg-gray-50 outline-none text-gray-900 text-sm rounded-l block w-full p-2"
                  placeholder="Эхлэх огноо"
                  value={startdate}
                  onChange={(e) => setStartdate(e.target.value)}
                />
                <input
                  type="date"
                  className="bg-gray-50 outline-none text-gray-900 text-sm block w-full p-2"
                  placeholder="Дуусах огноо"
                  value={enddate}
                  onChange={(e) => setEnddate(e.target.value)}
                />
                <div
                  className="px-4 h-[50px] flex items-center justify-center bg-green-600 text-white rounded-r cursor-pointer"
                  onClick={() => {
                    if (!loading) {
                      ClearDB();
                    }
                  }}
                >
                  {loading ? "Уншиж байна" : "Цэвэрлэх"}
                </div>
              </div>
            </div>
            <div className="mt-4 flex items-center gap-2">
              <InputSwitch
                checked={config_statement?.active}
                onChange={(e) => {
                  workStatement(e.value);
                }}
              />
              <label htmlFor="statement" className="text-white">
                Дансны хуулга
              </label>
              <div className="text-white font-semibold">{data?.value}</div>
            </div>
            <div className="mt-4 flex items-center gap-2">
              <InputSwitch
                checked={config_cargo?.active}
                onChange={(e) => {
                  configCargo(e.value);
                }}
              />
              <label htmlFor="statement" className="text-white">
                Ачаа буувал асаах
              </label>
            </div>
          </>
        )}

        <div
          className={`${
            state?.is_sa ? "mt-4" : "mt-0"
          } px-4 py-2 bg-green-600 text-white rounded cursor-pointer text-center`}
          onClick={() => PaymentCheck()}
        >
          Төлбөр шалгах
        </div>
        <input
          type="text"
          className="bg-white border mt-4 border-white text-gray-900 rounded block p-1.5 w-full outline-none"
          placeholder="Хайх утга бичнэ үү..."
          value={searchValue}
          onChange={(e) => {
            setSearchValue(e.target.value);
          }}
        />
        <div className="mt-2 flex flex-col justify-center bg-white rounded text-sm">
          {_.map(statement, (el, index) => {
            return (
              <div className="w-full p-2" key={index}>
                <div className="font-medium">
                  {moment(el[0]).format("YYYY.MM.DD")}
                </div>
                {_.map(el[1], (item, ind) => {
                  return (
                    <div
                      className="flex items-center gap-x-2 py-2 border-b"
                      key={"child_" + ind}
                    >
                      <FaRegArrowAltCircleDown className="text-green-700 text-xl" />
                      <div className="flex-1">
                        <div className="flex items-center justify-between font-medium">
                          <div>{item?.txnTime}</div>
                          <div className="text-green-700">
                            +{item?.amount} ₮
                          </div>
                        </div>
                        <div className="overflow-hidden text-xs">
                          {item?.transactionRemarks}
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            );
          })}
        </div>
      </div>
    </React.Fragment>
  );
};

export default React.memo(File);
