import React, { useContext, useEffect, useState } from "react";
import CostModal from "./costModal";
import { get, trash, put, post } from "./axios";
import { toast } from "react-toastify";
import _ from "lodash";
import { FaRegTrashAlt } from "react-icons/fa";
import { FiEdit } from "react-icons/fi";
import { FaRegCircleCheck, FaRegCircleXmark } from "react-icons/fa6";
import moment from "moment";
import AdminHeader from "./components/adminHeader";
import { Context } from "./context";
import { Link } from "react-router-dom";
import { Calendar } from "primereact/calendar";

const Cost = () => {
  const { state } = useContext(Context);

  const [modal, setModal] = useState(false);
  const [groupList, setGroupList] = useState([]);
  const [refresh, setrefresh] = useState(0);
  const [pid, setPid] = useState(null);
  const [name, setName] = useState("");
  const [price, setPrice] = useState("");
  const [metrkub, setMetrkub] = useState("");
  const [date, setDate] = useState(moment().format("YYYY-MM-DD"));
  const [tootsoo, setTootsoo] = useState([]);
  const [ashig, setAshig] = useState(moment()._d);
  const [ashig_data, setAshig_data] = useState(0);

  // const [margin, setMargin] = useState({});

  // useEffect(() => {
  //   get("/cargo/margin")
  //     .then((res) => {
  //       if (res.success) {
  //         setMargin(res?.data);
  //       }
  //     })
  //     .catch((err) => {
  //       toast.warn(err.message);
  //     });
  // }, []);

  useEffect(() => {
    post("/cargo/dashboard", {
      date: moment(ashig).format("YYYY-MM"),
    })
      .then((res) => {
        setAshig_data(res.data);
      })
      .catch((err) => {
        toast.warn(err.message);
      });
  }, [ashig]);

  useEffect(() => {
    get("/cargo/cost")
      .then((res) => {
        var slist = [];
        _.map(res?.data, (el) => {
          return slist.push({
            ...el,
            create_date: moment(el.create_date).format("YYYY-MM-DD"),
          });
        });
        setGroupList(
          Object.entries(
            _.groupBy(
              _.orderBy(_.slice(slist, -100), ["create_date"], ["desc"]),
              "create_date"
            )
          )
        );
        setTootsoo(
          _.orderBy(
            _.filter(res?.data, (item) => {
              return item.paid === false && item.name.includes("Эрээн - УБ");
            }),
            ["create_date"],
            ["asc"]
          )
        );
      })
      .catch((err) => {
        toast.warn(err.message);
      });
  }, [refresh]);

  const Delete = (id) => {
    if (id) {
      trash(`/cargo/cost/${id}`)
        .then((res) => {
          setrefresh(refresh + 1);
          toast.success(res.data);
        })
        .catch((err) => {
          toast.warn(err.message);
        });
    }
  };

  const Paid = (id, a) => {
    put(`/cargo/cost/${id}`, { paid: a })
      .then((res) => {
        if (res.success) {
          setrefresh(refresh + 1);
          toast.success(res.data);
        } else {
          toast.warn(res?.error);
        }
      })
      .catch((err) => {
        toast.warn(err?.message);
      });
  };

  if (!state?.loggedIn)
    return (
      <div className="w-screen h-screen flex items-center justify-center flex-col">
        <div className="text-white text-xl">Нэвтрэх шаардлагатай.</div>
        <Link
          to={"/login"}
          className="px-6 py-2 rounded-md bg-green-500 text-white mt-2"
        >
          Нэвтрэх
        </Link>
      </div>
    );

  if (!state?.is_sa)
    return (
      <div className="w-screen h-screen flex items-center justify-center flex-col">
        <div className="text-white text-xl">Хандах эрхгүй.</div>
        <Link
          to={"/"}
          className="px-6 py-2 rounded-md bg-green-500 text-white mt-2"
        >
          Нүүр хуудасруу буцах
        </Link>
      </div>
    );

  return (
    <div className="relative container mx-auto">
      <AdminHeader />
      <div
        className="bg-white rounded-full fixed bottom-24 right-4 w-10 h-10 flex items-center justify-center font-bold text-green-600 cursor-pointer"
        onClick={() => {
          setDate(moment().format("YYYY-MM-DD"));
          setPid(null);
          setName("");
          setPrice("");
          setMetrkub("");
          setModal(true);
        }}
      >
        +
      </div>
      <div className="flex items-center justify-center pt-2">
        <Calendar
          className=""
          placeholder="Сонгох"
          view="month"
          dateFormat="mm/yy"
          showButtonBar
          value={ashig}
          onChange={(e) => setAshig(e.value)}
        />
        <div className="text-xl bg-green-700 text-white px-2 py-1 rounded-xl">
          {parseFloat(ashig_data)?.toLocaleString()}
        </div>
      </div>
      <div className="text-base">
        ЗАРДАЛ
        {_.map(tootsoo, (el, index) => {
          return (
            <div key={"tt_" + index}>
              <span>{el?.create_date}</span> - <span>{el?.metrkub}</span> -{" "}
              <span>{parseInt(el?.price).toLocaleString()}</span>
            </div>
          );
        })}
        <div>
          <span>Нийт: </span>
          <span>
            {_.sumBy(tootsoo, function (i) {
              return parseInt(i.price);
            })?.toLocaleString()}
          </span>
        </div>
      </div>
      {/* <div className="text-sm">
        <div>Нийт орлого: {parseInt(margin?.total)?.toLocaleString()}</div>
        <div>Нийт зардал: {parseInt(margin?.total_cost)?.toLocaleString()}</div>
        <div>
          Төлөгдөөгүй орлого: {parseInt(margin?.unpaid)?.toLocaleString()}
        </div>
        <div>
          Дансаар:{" "}
          {parseInt(
            margin?.paid_account - margin?.total_cost
          )?.toLocaleString()}
        </div>
        <div>Бэлнээр: {parseInt(margin?.paid_cash)?.toLocaleString()}</div>
        <div className="font-semibold text-green-700">
          Нийт:{" "}
          {parseInt(
            parseInt(margin?.paid_account - margin?.total_cost) +
              margin?.paid_cash
          )?.toLocaleString()}
        </div>
      </div> */}

      <div className="w-full overflow-auto text-xs md:text-base">
        <table className="table-auto w-full">
          <thead>
            <tr>
              <th>Огноо</th>
              <th>Төрөл</th>
              <th>Метр куб</th>
              <th>Зардал</th>
              <th>#</th>
            </tr>
          </thead>
          <tbody>
            {_.map(groupList, (el, index) => {
              let totalPayment = el[1].reduce(
                (acc, curr) => acc + (parseInt(curr?.price) || 0),
                0
              );
              return (
                <React.Fragment key={index}>
                  <tr>
                    <td className="text-left" rowSpan={el[1]?.length + 1}>
                      <div className="flex flex-col items-center gap-2">
                        <div>{moment(el[0]).format("YYYY-MM-DD")}</div>
                      </div>
                    </td>
                  </tr>
                  {_.map(
                    _.orderBy(el[1], ["create_date"], ["asc"]),
                    (item, index2) => {
                      return (
                        <tr key={`${index}-${index2}`}>
                          <td className="text-left">{item?.name}</td>
                          <td className="text-left">{item?.metrkub}</td>
                          <td className="text-left">
                            {_.parseInt(item?.price).toLocaleString()}
                          </td>
                          <td className="text-center text-white font-semibold">
                            <div className="flex items-center justify-center gap-2">
                              {item?.paid ? (
                                <FaRegCircleXmark
                                  className="cursor-pointer text-2xl text-gray-500"
                                  onClick={() => Paid(item?.id, 0)}
                                />
                              ) : (
                                <FaRegCircleCheck
                                  className="cursor-pointer text-2xl text-green-500"
                                  onClick={() => Paid(item?.id, 1)}
                                />
                              )}
                              <FiEdit
                                className="cursor-pointer text-2xl text-blue-600"
                                onClick={() => {
                                  setDate(item?.create_date);
                                  setPid(item?.id);
                                  setName(item?.name);
                                  setPrice(item?.price);
                                  setMetrkub(item?.metrkub);
                                  setModal(true);
                                }}
                              />
                              <FaRegTrashAlt
                                className="cursor-pointer text-2xl text-red-600"
                                onClick={() => Delete(item?.id)}
                              />
                            </div>
                          </td>
                        </tr>
                      );
                    }
                  )}
                  <tr className="font-semibold">
                    <td className="text-right" colSpan={2}></td>
                    <td className="text-center" colSpan={1}>
                      Нийт:
                    </td>
                    <td className="text-left" colSpan={2}>
                      {parseInt(totalPayment)?.toLocaleString()}
                    </td>
                  </tr>
                </React.Fragment>
              );
            })}
          </tbody>
        </table>
      </div>

      <CostModal
        date={date}
        setDate={setDate}
        setModal={setModal}
        modal={modal}
        setrefresh={setrefresh}
        refresh={refresh}
        name={name}
        setName={setName}
        price={price}
        setPrice={setPrice}
        metrkub={metrkub}
        setMetrkub={setMetrkub}
        pid={pid}
        setPid={setPid}
      />
    </div>
  );
};

export default React.memo(Cost);
