import React, { useState, useContext } from "react";
import { post } from "./axios";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { Context } from "./context";
import { jwtDecode } from "jwt-decode";

const Login = () => {
  const { state, dispatch } = useContext(Context);
  const navigate = useNavigate();
  const [phone, setPhone] = useState("");
  const [password, setPassword] = useState("");

  const login = async () => {
    if (phone && password) {
      try {
        const res = await post("/user/signin", { email: phone, password });
        if (res?.success) {
          localStorage.setItem("token", res?.token);
          dispatch({
            type: "CHANGE",
            data: {
              token: res?.token,
              loggedIn: true,
            },
          });
          const decoded = jwtDecode(res?.token);
          if (decoded?.role === "admin") {
            navigate("/arrived");
          } else {
            navigate("/ub");
          }
        } else {
          toast.warn(res.error);
        }
      } catch (error) {
        toast.warn(error.message);
      }
    }
  };

  return (
    <div className="flex items-center justify-center w-full min-h-screen h-full flex-col gap-2 px-4 md:w-[700px] md:mx-auto">
      <div
        className="text-white text-3xl"
        onClick={() => {
          if (state?.loggedIn) {
            navigate("/arrived");
          }
        }}
      >
        Нэвтрэх
      </div>
      {/* <input
        type="text"
        inputMode={["decimal"]}
        pattern="[0-9]"
        value={value}
        onChange={(e) => {
          setValue(e.target.value);
        }}
        onKeyPress={(e) => {
          if (e.nativeEvent.key === ".") {
            e.preventDefault();
            alert("asd");
          }
        }}
      /> */}
      <input
        type="text"
        className="bg-white border border-white text-gray-900 rounded block p-2.5 w-full outline-none"
        placeholder="Нэвтрэх нэр"
        value={phone}
        onChange={(e) => {
          setPhone(e.target.value);
        }}
      />
      <input
        type="password"
        className="bg-white border border-white text-gray-900 rounded block p-2.5 w-full outline-none"
        placeholder="Нууц үг"
        value={password}
        onChange={(e) => {
          setPassword(e.target.value);
        }}
        onKeyDown={(e) => {
          if (e.key === "Enter") {
            login();
          }
        }}
      />
      <div
        className="px-4 py-2 rounded bg-green-600 text-white"
        onClick={() => {
          login();
        }}
      >
        Нэвтрэх
      </div>
    </div>
  );
};

export default Login;
